import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineWhatsApp } from 'react-icons/ai'; // Icona WhatsApp
import { FaCheckCircle } from 'react-icons/fa'; // Icona Privacy Check

export default function CtaTesto({ ctatesti }) {
  const [isModalOpen, setIsModalOpen] = useState(false); // Stato per il popup
  const [formData, setFormData] = useState({ nome: '', cognome: '', email: '', telefono: '', privacy: false });
  const [error, setError] = useState(''); // Stato per gestire gli errori
  const modalRef = useRef(null); // Ref per il rilevamento del click fuori dal popup

  // Funzione per aprire il popup
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Funzione per chiudere il popup
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Funzione per gestire il cambiamento nei campi del modulo
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Validazione del modulo
  const validateForm = () => {
    if (!formData.email && !formData.telefono) {
      setError('Inserisci almeno l\'email o il telefono.');
      return false;
    }
    if (!formData.privacy) {
      setError('Devi accettare la Privacy Policy.');
      return false;
    }
    setError('');
    return true;
  };

  // Gestione dell'invio del modulo
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Invia il modulo (simulazione)
      alert('Modulo inviato correttamente!');
      closeModal();
    }
  };

  // Chiudi il popup se l'utente clicca fuori
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <section id="ctatesto" className="md:p-10 p-6">
      <div className="bg-white">
        <div className="mx-auto w-full px-4 pt-18 pb-8 sm:px-6 lg:px-8">
          <div className="rounded-2xl px-6 pt-16 pb-16 sm:p-16">
            <div className="mx-auto max-w-xl lg:max-w-none">
              <div className="text-center">
                <h2
                  data-aos-duration={500}
                  data-aos="fade-up"
                  data-aos-delay={500}
                  style={{ marginBottom: '0px' }}
                  className="md:text-6xl text-4xl font-black tracking-tight text-gray-900"
                >
                  {ctatesti[0].row1}
                </h2>
                <h2
                  data-aos-duration={500}
                  data-aos="fade-up"
                  data-aos-delay={500}
                  style={{ marginBottom: '20px'   }}
                  className="text-[50px] md:text-[90px] leading-10 md:leading-relaxed md:mt-0 mt-2 font-black tracking-tight text-giallomoretti"
                >
                  {ctatesti[0].row2}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mx-auto items-center max-w-8xl px-4 mb-40 sm:px-6 lg:px-8">
          <p
            data-aos-duration={500}
            data-aos="fade-up"
            data-aos-delay={700}
            className="text-5xl leading-tight mb-16"
            dangerouslySetInnerHTML={{ __html: ctatesti[0].row3 }}
          ></p>

          {/* Pulsante che apre il popup */}
          <button
            data-aos-duration={500}
            data-aos-delay={900}
            data-aos="fade-up"
            type="button"
            onClick={openModal}
            className="rounded-full bg-giallomoretti md:px-40 px-5 py-5 text-2xl md:text-5xl font-black text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Contattaci ora!
          </button>

          {/* Popup */}
          {isModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div ref={modalRef} className="bg-white rounded-lg p-8 max-w-lg w-full relative">
                {/* Pulsante di chiusura */}
                <button
                  onClick={closeModal}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl"
                >
                  ✕
                </button>

                {/* Titolo del popup */}
                <h3 className="text-3xl font-black text-left mb-4">Contattaci</h3>

                {/* Messaggio descrittivo */}
                <p className="text-gray-600 text-left mb-6">
                  Inserisci il tuo <strong>nome</strong>, <strong>cognome</strong>, e lascia
                  almeno l'email o il numero di telefono. Verrai ricontattato da uno
                  dei nostri consulenti.
                </p>
                
                {/* Messaggio di errore */}
                {error && <p className="text-red-500 text-left mb-4">{error}</p>}

                {/* Modulo di contatto */}
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className=" text-left block text-lg font-medium mb-2">Nome</label>
                    <input
                      type="text"
                      name="nome"
                      value={formData.nome}
                      onChange={handleInputChange}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Inserisci il tuo nome"
                    />
                  </div>
                  <div className="mb-4">
                    <label className=" text-left  block text-lg font-medium mb-2">Cognome</label>
                    <input
                      type="text"
                      name="cognome"
                      value={formData.cognome}
                      onChange={handleInputChange}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Inserisci il tuo cognome"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-left text-lg font-medium mb-2">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Inserisci la tua email"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-left  text-lg font-medium mb-2">Telefono</label>
                    <input
                      type="tel"
                      name="telefono"
                      value={formData.telefono}
                      onChange={handleInputChange}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Inserisci il tuo numero di telefono"
                    />
                  </div>

                  {/* Checkbox Privacy Policy */}
                  <div className="mb-4 flex items-left">
                    <input
                      type="checkbox"
                      name="privacy"
                      checked={formData.privacy}
                      onChange={handleInputChange}
                      className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label className="ml-2 text-lg font-medium">
                      Accetto la <a href="/privacy" className="text-blue-600 underline">Privacy Policy</a>
                    </label>
                    <FaCheckCircle className="ml-2 text-green-500" />
                  </div>

                  {/* Pulsante per inviare il modulo */}
                  <button
                    type="submit"
                    className="w-full py-3 bg-giallomoretti text-black font-black rounded-lg hover:bg-indigo-500"
                  >
                    Invia
                  </button>
                </form>

                {/* Pulsante WhatsApp */}
                <a
                  href="https://wa.me/1234567890"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 w-full inline-block text-center py-3 bg-green-500 text-white font-black rounded-lg hover:bg-green-700 flex items-center justify-center"
                >
                  <AiOutlineWhatsApp className="text-2xl mr-2" /> Contattaci su WhatsApp
                </a>
              </div>
            </div>
          )}

          <div className="mt-16 max-w-5xl text-center mx-auto items-center max-w-8xl px-4 mb-40 sm:px-6 lg:px-8">
            <p
              data-aos-duration={500}
              data-aos="fade-up"
              data-aos-delay={1100}
              className="md:text-5xl text-3xl leading-tight mb-32"
              dangerouslySetInnerHTML={{ __html: ctatesti[0].row4 }}
            ></p>
          </div>
        </div>
      </div>
    </section>
  );
}
