import React from 'react';

// Il componente accetta le immagini come props
export default function TreImmagini({ imagestreimmagini }) {
  return (
    <section id="treimmagini" className="bg-white p-0 ">

    <div className="w-screen grid grid-cols-1 md:grid-cols-3 gap-0">
      {imagestreimmagini.map((src, index) => (
        <img
          key={index}
          src={src}
          alt={`Immagine ${index + 1}`}
          className="w-full md:h-auto object-cover"
          data-aos-duration={500} // Ogni immagine ha una durata crescente
          data-aos="fade-up"
          data-aos-delay={index * 300} // Ogni immagine appare con un ritardo crescente
           width="1282" 
           height="1328" 
           loading="auto"
      
 
         />
      ))}
    </div>
    </section>
  );
}
