import { ShieldCheckIcon, CursorArrowRaysIcon , ScaleIcon   } from '@heroicons/react/20/solid';

export default function PuntiDiForza({ i3Titolo, i3Icone, i3Button }) {
    const primaryFeatures = [
        {
          name: 'Robustezza senza compromessi',
          description: 'Progettato per resistere a carichi pesanti e a condizioni di lavoro impegnative',
          icon: ShieldCheckIcon,
        },
        {
          name: 'Precisione e maneggevolezza',
          description: 'Progettato per resistere a carichi pesanti e a condizioni di lavoro impegnative',
          icon: CursorArrowRaysIcon ,
        },
        {
          name: 'Risparmio e qualità',
          description: 'La migliore soluzione per ottimizzare la tua logistica senza compromettere la qualità',
          icon: ScaleIcon  ,
        },
      ]
    return (
<div className="mx-auto">
  <div className="relative isolate overflow-hidden sm:rounded-3xl py-20">
    <div className="align-middle mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0 text-left">
      <div className="flex flex-col justify-center lg:row-start-1 lg:px-40 px-10 lg:pt-10 h-full">
        <div className="lg:row-start-2">
          <h3 data-aos="zoom-in-up" className="text-1xl font-bold tracking-tight text-white text-2xl lg:mt-0 mt-14">
            Il Numero 1 per la Tua Logistica
          </h3>
          <h2 data-aos="zoom-in-up" data-aos-delay={300} className="text-3xl font-bold tracking-tight text-giallomoretti sm:text-6xl">
            Affidabilità Garantita!
          </h2>
        </div>

        <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md pt-10">
          <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
            {primaryFeatures.map((feature, index) => (
              <div 
              data-aos-duration={500} // Ogni immagine ha una durata crescente
              data-aos="fade-right"
              data-aos-delay={index * 300} // Ogni immagine appare con un ritardo crescente
              key={feature.name} className="relative lg:border-t lg:border-white/40 pt-5">
                <dt className="uppercase block font-semibold text-white">{feature.name}</dt>
                <dd className="block">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <img
        alt="Transpallet elettrico Jungheinrich EJE 116"
        data-aos="zoom-in-up"
        src="images/ej110.webp"
        width="690" 
        height="640" 
        loading="auto"
        className="relative -z-20 min-w-full max-w-xl rounded-xl lg:row-span-4 lg:w-[32rem] lg:max-w-none"
      />
    </div>
  </div>
</div>

    
        );
    }