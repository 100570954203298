import React from 'react';
import PuntiDiForza from '../puntidiforza/puntidiforza.component';

const LightEffectSection = () => {
  return (
    <section className="relative bg-black text-white flex items-center justify-center overflow-hidden w-full">
      {/* Content of the section */}
      <div className="w-full relative z-10 text-center mx-auto ">
      <PuntiDiForza />
      </div>

      </section>
  );
};

export default LightEffectSection;

