import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

// Modifica il componente per accettare le `props`
export default function AccordionMoretti({ faqs }) {
  return (
    <section id="faq" className="bg-yellowAccordion md:p-10 p-6">
    <div className="container mx-auto items-center bg-transparent">
      <h2 className="md:text-6xl text-4xl font-bold mb-6 text-center ">FAQ | Domande frequenti</h2>
    <div >
      <div className="mx-auto max-w-7xl px-6 py-14 sm:py-32 lg:px-8 lg:py-20">
        <div className="mx-auto max-w-6xl divide-y divide-gray-900/10">
          <dl className="mt-5 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq, index) => (
              <Disclosure key={index} 
              data-aos-duration={500} // Ogni immagine ha una durata crescente
              data-aos="fade-right"
              data-aos-delay={index * 300} // Ogni immagine appare con un ritardo crescente
              as="div" className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="group flex w-full items-start justify-between text-left text-gray-900">
                        <span className="md:text-4xl text-2xl font-bold leading-13">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon aria-hidden="true" className="h-5 w-5" />
                          ) : (
                            <PlusSmallIcon aria-hidden="true" className="h-5 w-5" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12 py-10">
                      <p className="text-1xl leading-7 md:text-3xl">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
    </div>
    </section>
  );
}
