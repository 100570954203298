import React, { useEffect } from 'react';
import AccordionMoretti from './components/accordion/accordion.component';
import TreImmagini from './components/treimmagini/treimmagini.componet';
import CtaTreIcone from './components/ctatreicone/ctatreicone.component';
import PuntiDiForza from './components/puntidiforza/puntidiforza.component';
import LightEffectSection from './components/lightsection/lightsection.component';
import ImmTesto from './components/immtesto/immtesto.component';
import CtaTesto from './components/ctatesto/ctatesto.component';
import Footer from './components/footer/footer.component';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  const ctatesti = [
    {
      row1: 'Fai il Prossimo Passo!',
      row2: 'Contattaci Subito!',
      row3: 'Scrivici una mail e usa il codice <br><span class="font-black">PROMO MORETTI</span>',
      row4: 'Un nostro esperto ti risponderà subito con tutte le informazioni per finalizzare l’ordine.'
    }
  ];
  const firstcontent = [
    {
      red: 'Offerta Imperdibile!',
      prodotto: 'Transpallet Elettrico Jungheinrich EJE 116',
      prezzo: '€4.350 +iva',
      testosotto: 'Capacità di carico: <br/><span class="font-black">1,6 tonnellate</span> <br/>Versione promozionata: <br/><span class="font-black">Batteria al piombo</span>'
    }
  ];

  const imagestreimmagini = [
    '/images/02.webp',
    '/images/03.webp',
    '/images/05.webp',
  ];

  const faqs = [
    {
      question: "Qual è la capacità di carico del Jungheinrich EJE 116?",
      answer: (
        <span>
          Il transpallet ha una capacità di <strong>1,6 tonnellate</strong>.
        </span>
      ),
    },
    {
      question: "La promozione include versioni con batteria al litio?",
      answer: (
        <span>
          No, l'offerta si applica solo ai modelli con <strong>batteria al piombo</strong>. Tuttavia abbiamo a disposizione anche un vasto assortimento di macchine al litio.
        </span>
      ),
    },
    {
      question: "Qual è la garanzia del prodotto?",
      answer: (
        <span>
          Il transpallet EJE 116 è coperto da una <strong>garanzia completa di 12 mesi</strong>, così da garantirti la massima sicurezza nel tuo investimento.
        </span>
      ),
    },
    {
      question: "Quanto durerà questa promozione?",
      answer: (
        <span>
          La promozione è valida fino ad esaurimento scorte, quindi ti consigliamo di agire rapidamente!
        </span>
      ),
    },
    {
      question: "Come posso ordinare il mio transpallet?",
      answer: (
        <span>
          Basta contattarci via Email utilizzando il codice promo <strong>PROMO MORETTI</strong> per ricevere tutte le informazioni.
        </span>
      ),
    },
  ];

  return (
    <div id="container" className="flex flex-col min-h-screen">
      <main className="flex-grow font-sans">
        <section>
          <ImmTesto firstcontent={firstcontent} />
        </section>

        <section>
          <LightEffectSection />
        </section>

        <section>
          <TreImmagini imagestreimmagini={imagestreimmagini} />
        </section>

        <section>
          <AccordionMoretti faqs={faqs} />
        </section>

        <section>
          <CtaTesto ctatesti={ctatesti}/>
        </section>

        <Footer />
      </main>
    </div>
  );
}
