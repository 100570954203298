export default function Footer({ firstcontent }) {
    return (
        <section id="footer" className="border-t-2 w-full">

        <footer className="">
            <div className="p-6   md:p-10 max-w-[1000px] mx-auto px-4  text-center items-center">
                <div className="border-b-2 py-12 max-w-5xl text-3xl text-center">
                    <div className="text-black">
                    ATTENZIONE<br/>
Le scorte stanno terminando! Solo le prime aziende che ci contatteranno potranno accedere a questa offerta esclusiva. Non perdere l’occasione di migliorare la tua logistica con un prodotto top di gamma a un prezzo irripetibile!
                    </div>  
          
                </div>
            
                <div className="mt-8 text-center text-white">
                    <p>&copy; 2021 Moretti Ugo Srl - P.IVA 123456789</p>
                </div>
            </div>
            <div className=" bg-gray-100 p-20 w-full flex justify-center ">
    <img src="images/logo_moretti.svg"  alt="Logo Moretti Footer" width="348" height="212" className="h-32 mt-10" />
  </div>
        </footer>
    </section>
        )
    }